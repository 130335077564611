<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-4">
                    <strong>Buscar en la tabla: </strong>
                    <input class="ml-2" type="text" v-model="filter" />
                  </div>
                  <div class="col-md-8">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-Teppostulacion-export"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  id="postulaciones"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="listasForms.slct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          :options="empresas"
                          @search="buscarEmpresas"
                          @input="filtrarTabla()"
                        ></v-select>
                      </th>
                      <th>
                        Fecha Inicio
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_inicial"
                          @change="filtrarTabla()"
                        />
                      </th>
                      <th>
                        Fecha Fin
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_final"
                          @change="filtrarTabla()"
                        />
                      </th>
                      <th>
                        <label>Sitio</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="listasForms.slct_sitio"
                          placeholder="Sitio"
                          label="nombre"
                          :options="sitios"
                          :filterable="false"
                          @search="buscarSitios"
                          @input="filtrarTabla()"
                        ></v-select>
                      </th>
                      <th>
                        <label>Nominacion</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="listasForms.slct_nominacion"
                          placeholder="Nominacion"
                          label="nombre"
                          :options="nominaciones"
                          :filterable="false"
                          @search="buscarNominaciones"
                          @input="filtrarTabla()"
                        ></v-select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, index) in filteredRows"
                      :key="`id-${index}`"
                    >
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td
                        v-html="
                          highlightMatches(row.tep_transportadora.razon_social)
                        "
                      ></td>
                      <td
                        v-html="highlightMatches(row.tep_nominacion.fecha_ini)"
                      ></td>
                      <td
                        v-html="highlightMatches(row.tep_nominacion.fecha_fin)"
                      ></td>
                      <td
                        v-html="
                          highlightMatches(row.tep_nominacion.sitio.nombre)
                        "
                      ></td>
                      <td
                        v-html="highlightMatches(row.tep_nominacion.nombre)"
                      ></td>
                      <td style="width: 40px" class="text-center">
                        <div class="btn-group float-center">
                          <button
                            type="button"
                            v-if="
                              $store.getters.can('tep.postulaciones.create')
                            "
                            @click="
                              asignarVehiculos(
                                row.tep_nominacion,
                                row.empresa_id,
                                1
                              )
                            "
                            class="btn btn-sm bg-navy"
                          >
                            <i class="fas fa-calendar-plus"></i>
                          </button>
                          <button
                            type="button"
                            v-if="$store.getters.can('tep.postulaciones.show')"
                            @click="
                              asignarVehiculos(
                                row.tep_nominacion,
                                row.empresa_id,
                                2
                              )
                            "
                            class="btn btn-sm bg-primary"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="postulacionesTep.total">
                  <p>
                    Mostrando del <b>{{ postulacionesTep.from }}</b> al
                    <b>{{ postulacionesTep.to }}</b> de un total:
                    <b>{{ postulacionesTep.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="postulacionesTep"
                  @pagination-change-page="filtrarTabla"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <TepPostulacionExport ref="TepPostulacionExport" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import { mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import TepPostulacionExport from "./TepPostulacionExport";
export default {
  name: "PostulacionIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    TepPostulacionExport,
  },
  data() {
    return {
      distancia: null,
      postulaciones: [],
      filtros: {},
      filter: "",
      listasForms: {
        slct_empresa: [],
        estados: [],
        slct_nominacion: [],
        tipoOperaciones: [],
        slct_sitio: [],
      },
      nominaciones: [],
      empresas: [],
      sitios: [],
    };
  },

  beforeMount() {
    this.cargando = true;

    (this.filtros = {
      empresa_id: null,
      sitio_id: null,
      fecha_inicial: null,
      fecha_final: null,
      nominacion_id: null,
    }),
      this.actGetPostulaciones(this.filtros).then(() => {
        this.postulaciones = this.postulacionesTep.data;
        this.cargando = false;
      });
  },

  computed: {
    ...mapState("modOperacion", ["listas"]),
    ...mapState("modTep", ["postulacionesTep"]),
    ...mapState("modTep", ["loading"]),

    filteredRows() {
      return this.postulaciones.filter((row) => {
        const empresa = row.tep_transportadora.razon_social.toLowerCase();
        const sitio = row.tep_nominacion.sitio.nombre.toLowerCase();
        const nominacion = row.tep_nominacion.nombre.toLowerCase();
        const fecha_inicial = row.tep_nominacion.fecha_ini;
        const fecha_final = row.tep_nominacion.fecha_fin;
        const estado = row.estado == 1 ? "definitivo" : "borrador";
        const tipo_operacion = row.nTipoOperacion.toLowerCase();
        const searchTerm = this.filter.toLowerCase();

        return (
          empresa.includes(searchTerm) ||
          sitio.includes(searchTerm) ||
          nominacion.includes(searchTerm) ||
          fecha_inicial.includes(searchTerm) ||
          fecha_final.includes(searchTerm) ||
          estado.includes(searchTerm) ||
          tipo_operacion.includes(searchTerm)
        );
      });
    },
  },

  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modTep", ["actGetPostulaciones"]),

    highlightMatches(texto) {
      var text = String(texto);
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },

    filtrarTabla(page = 1) {
      this.cargando = true;
      var data = [];
      (this.filtros = {
        empresa_id: this.listasForms.slct_empresa
          ? this.listasForms.slct_empresa.id
          : null,
        sitio_id: this.listasForms.slct_sitio
          ? this.listasForms.slct_sitio.id
          : null,
        fecha_inicial: this.filtros.fecha_inicial
          ? this.filtros.fecha_inicial
          : null,
        fecha_final: this.filtros.fecha_final ? this.filtros.fecha_final : null,
        nominacion_id: this.listasForms.slct_nominacion
          ? this.listasForms.slct_nominacion.id
          : null,
      }),
        (data[0] = page);
      data[1] = this.filtros;
      /* alert(JSON.stringify(this.filtros)); */
      this.postulaciones = [];
      this.actGetPostulaciones(data).then(() => {
        this.postulaciones = this.postulacionesTep.data;
        this.cargando = false;
      });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        axios
          .get("/api/admin/empresas/lista", {
            params: {
              linea_negocio_id: [5],
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarSitios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.sitios = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarNominaciones(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/tep/nominaciones/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.nominaciones = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoOperaciones() {
      axios.get("/api/lista/80").then((response) => {
        this.listasForms.tipoOperaciones = response.data;
      });
    },

    asignarVehiculos(nomi, empresa, accion) {
      return this.$router.push({
        name: "/Tep/PostulacionForm",
        params: {
          nominacion: nomi,
          empresa_id: empresa,
          accion: accion,
        },
      });
    },
  },
  mounted() {
    this.getEstados();
    this.getTipoOperaciones();
  },
};
</script>
